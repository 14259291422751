import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Image, Input, Layout, Row } from 'antd';
import { SearchOutlined, FacebookFilled } from '@ant-design/icons';
import { useCookies } from 'react-cookie';

const { Header } = Layout;

const AppHeader: React.FC<{ setSearchDeal?: any }> = ({ setSearchDeal }) => {
  const routerPath = window.location.pathname.replace('/', '');
  const [currentUser, setCurrentUser] = useState<any>();
  const [cookies] = useCookies(['currentUser']);

  const [form] = Form.useForm();

  useEffect(() => {
    setCurrentUser(cookies.currentUser);
  }, [cookies.currentUser]);

  const currentSearchLink = sessionStorage.getItem('search');

  useEffect(() => {
    const user = cookies.currentUser;
    setCurrentUser(user);
    if (currentSearchLink) {
      form.setFieldsValue({
        keyword: currentSearchLink,
      });
    }
  }, []);

  return (
    <Header className="app-header vt__header">
      <Row>
        <Col span={18}>
          <h2 className="vt__header-title mb-0">voucher today </h2>
        </Col>
        <Col
          span={6}
          style={{
            textAlign: 'right',
          }}
        >
          <Button
            type="text"
            href={'https://www.facebook.com/vouchertoday.org'}
            target={'_blank'}
            size={'large'}
            style={{ verticalAlign: '-webkit-baseline-middle' }}
            icon={
              <FacebookFilled
                style={{
                  fontSize: '32px',
                  color: 'white',
                }}
              />
            }
          />
        </Col>
      </Row>

      {routerPath !== 'user' && (
        <Form form={form}>
          <Row>
            <Col xs={24}>
              <Form.Item name={'keyword'} label={''}>
                <Input
                  prefix={<SearchOutlined />}
                  allowClear
                  className={'w-100'}
                  placeholder={
                    routerPath !== 'productOffer' && routerPath !== 'deals'
                      ? 'Nhập link sp Shopee, Lazada để tìm mã'
                      : 'Nhập từ khoá để tìm kiếm deal'
                  }
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                      if (
                        routerPath !== 'productOffer' &&
                        routerPath !== 'deals'
                      ) {
                        sessionStorage.setItem('search', e.target.value.trim());
                        window.location.href = 'search';
                      } else {
                        setSearchDeal(e.target.value);
                      }
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
      {routerPath === 'user' && currentUser && (
        <Row gutter={20} className="vt__header-user">
          <Col xs={5}>
            <Image
              style={{ borderRadius: '50%', width: '50px', height: '50px' }}
              src={currentUser && currentUser.picture.data.url}
              preview={false}
            />
          </Col>
          <Col xs={19}>
            <span style={{ color: '#ffffff', fontSize: '22px' }}>
              {currentUser.name}
            </span>
          </Col>
        </Row>
      )}
    </Header>
  );
};

export default AppHeader;
