import { notification } from 'antd';
import { AxiosError } from 'axios';
import { t } from 'helpers/i18n';
import { notifHelpers } from './index';

const handleResponseError = (error: AxiosError) => {
  const status = error && error.response && error.response.status;
  switch (status) {
    case 401:
      // @ts-ignore
      notifHelpers.error('Có lỗi xảy ra');
      break;
    case 403:
      notifHelpers.error('Bạn không có quyền');
      break;
    default:
      let message = null;
      // Handle error message from API response
      if (error.response && error.response.data) {
        const { data } = error.response;
        message = data.message;
      }
      notification.error({
        message: t('Error'),
        description: message || t('SomethingWentWrong'),
      });
      break;
  }
};

export const getResult = (response: any) => response.data;

export const getResultAndHeaders = (response: any) => {
  return {
    result: response.data,
    headers: response.headers,
  };
};

export default {
  handleResponseError,
};
