import { notification } from 'antd';
import { t } from './i18n';

const error = (description: string, options = {}, message = 'ErrorNotif') => {
  notification.error({
    message: t(message),
    description: t(description, options),
  });
};

const success = (
  description: string,
  options = {},
  message = 'SuccessNotif'
) => {
  notification.success({
    message: t(message),
    description: t(description, options),
  });
};

export default {
  error,
  success,
};
