export const currentUser = {
  name: 'xxx',
  picture:
    'https://tophinhanhdep.com/wp-content/uploads/2021/10/1080P-Wallpapers.jpg',
  email: 'toilda.ip@gmail.com',
  roles: ['catalog:admin'],
  permissions: ['catalog:all:all', 'ppm:all:all'],
};

const isLoggedIn = () => true;
const login = () => {};
const logout = () => {};
const getAccessToken = () => '1';
const getUserInfo = () => currentUser;
const getFullUserInfo = () => Promise.resolve(currentUser);
const denyAccess = () => {};

export default {
  isLoggedIn,
  login,
  logout,
  getAccessToken,
  getUserInfo,
  getFullUserInfo,
  denyAccess,
};
