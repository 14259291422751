import React, { useState } from 'react';
import { Layout } from 'antd';
import { userHooks } from 'hooks';
import routes from 'routes';
import { IRoute } from 'interfaces';
import AppHeader from './AppHeader';
import AppContent from './AppContent';
import './AppLayout.scss';
import AppMenu from './AppMenu';
import { commonHelpers } from '../../helpers';
import { SearchContext } from '../../contexts';
import { useCookies } from 'react-cookie';

/**
 * This container is for lifting-up the `AppContent` to the parent node,
 * so we can avoid unnecessary re-calculation when resizing window
 * */

const { useQuery } = commonHelpers;

const AppLayoutContainer: React.FC<{
  filteredNavigation: IRoute[];
  setSearchDeal?: any;
  children: React.ReactNode;
}> = ({ children, setSearchDeal }) => {
  const query = useQuery();
  const isShow = query.get('isShow');
  const [cookie, setCookie] = useCookies(['ctoken']);

  const ctoken = query.get('ctoken');
  if (ctoken) {
    setCookie('ctoken', ctoken, {
      path: '/',
      expires: new Date(new Date().getTime() + 7200000),
    });
    console.log('set cookie:' + cookie.ctoken);
  }
  return (
    <Layout className={`app-layout ${isShow === 'false' ? 'hide-header' : ''}`}>
      <Layout>
        <AppHeader setSearchDeal={setSearchDeal} />
        {children}
        {isShow !== 'false' && <AppMenu />}
      </Layout>
    </Layout>
  );
};

const AppLayout: React.FC = () => {
  const { filteredRoutes, filteredNavigation } = userHooks.useAuthorizationData(
    routes as IRoute[]
  );

  const [searchDeal, setSearchDeal] = useState<string>('');

  return (
    <SearchContext.Provider value={searchDeal}>
      <AppLayoutContainer
        filteredNavigation={filteredNavigation}
        setSearchDeal={setSearchDeal}
      >
        <AppContent filteredRoutes={filteredRoutes} />
      </AppLayoutContainer>
    </SearchContext.Provider>
  );
};

export default AppLayout;
