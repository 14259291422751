import React, { useState } from 'react';
import { Menu } from 'antd';
import {
  SearchOutlined,
  HomeOutlined,
  UserOutlined,
  FireOutlined,
  FundOutlined,
} from '@ant-design/icons';

const AppMenu = () => {
  const routerPath = window.location.pathname;

  const [current, setCurrent] = useState<any>(
    routerPath.replace('/', '') || '/'
  );

  const handleClick = (e: any) => {
    setCurrent(e.key);
    if (e.key === '/') {
      window.location.href = '/';
    } else if (e.key === 'search') {
      window.location.href = '/search';
    } else if (e.key === 'productOffer') {
      window.location.href = '/productOffer';
    } else if (e.key === 'deals') {
      window.location.href = '/deals';
    } else if (e.key === 'user') {
      window.location.href = '/user';
    }
    sessionStorage.removeItem('search');
  };

  return (
    <>
      <Menu
        onClick={handleClick}
        selectedKeys={[current]}
        mode="horizontal"
        className="voucher-today__menu"
      >
        <Menu.Item key="/">
          <span className="item-menu">
            <HomeOutlined />
            <p className="item-menu-text">Trang chủ</p>
          </span>
        </Menu.Item>
        <Menu.Item key="search">
          <span className="item-menu">
            <SearchOutlined />
            <p className="item-menu-text">Tìm kiếm</p>
          </span>
        </Menu.Item>
        <Menu.Item key="productOffer">
          <span className="item-menu">
            <FireOutlined />
            <p className="item-menu-text">Tích lũy</p>
          </span>
        </Menu.Item>
        <Menu.Item key="deals">
          <span className="item-menu">
            <FundOutlined />
            <p className="item-menu-text">Hoàn tiền</p>
          </span>
        </Menu.Item>
        <Menu.Item key="user">
          <span className="item-menu">
            <UserOutlined />
            <p className="item-menu-text">Cá nhân</p>
          </span>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default AppMenu;
