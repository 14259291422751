import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { requestHelpers } from 'helpers';
import { Cookies } from 'react-cookie';

const baseURL = 'https://api.vouchertoday.org';
// const baseURL = 'https://lucky-roses-battle-103-17-236-24.loca.lt/';

const voucherClient = axios.create({
  baseURL: window.REACT_APP_VOUCHER_ENDPOINT,
});

const voucherClient2 = axios.create({
  baseURL: baseURL,
});

const getAuthorization = () => {
  const cookies = new Cookies();
  const user = cookies.get('currentUser')
    ? JSON.stringify(cookies.get('currentUser'))
    : '';
  return `${encodeURIComponent(unescape(user))}`;
};

const getCtoken = () => {
  const cookies = new Cookies();
  return cookies.get('ctoken');
};

// Do something before request is sent
const requestInterceptor = (request: AxiosRequestConfig) => {
  request.headers.Authorization = getAuthorization();
  request.headers.Ctoken = getCtoken();
  return request;
};

// Any status code that lie within the range of 2xx cause this function to trigger
const responseSuccessInterceptor = (response: AxiosResponse) => {
  // Do something with response data
  return response;
};

// Any status codes that falls outside the range of 2xx cause this function to trigger
const responseErrorInterceptor = (error: AxiosError) => {
  // Do something with response error
  requestHelpers.handleResponseError(error);
  return Promise.reject(error);
};

const clients = [voucherClient];
const clients2 = [voucherClient2];

clients.forEach(client => {
  client.interceptors.request.use(requestInterceptor);
  client.interceptors.response.use(
    responseSuccessInterceptor,
    responseErrorInterceptor
  );
});

clients2.forEach(client => {
  client.interceptors.request.use(requestInterceptor);
  client.interceptors.response.use(
    responseSuccessInterceptor,
    responseErrorInterceptor
  );
});

export default {
  voucherClient,
  voucherClient2,
};
