import { IPermission } from 'interfaces';
import { useLocation } from 'react-router-dom';
import { PRICE_FORMAT } from '../constants/common';

export const permission = (
  app: IPermission['app'],
  resource: IPermission['resource'],
  action?: IPermission['action']
) => ({
  app,
  resource,
  action,
});

const getWindowDimensions = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const formatPrice = (price: number | string) => {
  return `${price}`.replace(PRICE_FORMAT, '.');
};

export default {
  getWindowDimensions,
  useQuery,
  formatPrice,
};
