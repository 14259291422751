import React, { useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { browserHistory } from 'helpers';
import './App.less';
import './App.scss';
import Page403 from 'containers/shared/Page403';
import Page404 from 'containers/shared/Page404';
import Page500 from 'containers/shared/Page500';
import PrivateRoute from 'components/shared/PrivateRoute';
import AppLayout from 'containers/AppLayout';
import { requestFirebaseNotificationPermission } from './firebaseInit';
import { saveFcmToken } from './services/apis/voucher';
import firebase from 'firebase/app';

const App: React.FC = () => {
  useEffect(() => {
    if (firebase.messaging.isSupported()) {
      requestFirebaseNotificationPermission()
        .then(firebaseToken => {
          // eslint-disable-next-line no-console
          console.log(firebaseToken);
          saveFcmToken(firebaseToken).then();
        })
        .catch(err => {
          return err;
        });
    }
  }, []);

  return (
    <Router history={browserHistory}>
      <Switch>
        <Route exact path="/403" component={Page403} />
        <Route exact path="/404" component={Page404} />
        <Route exact path="/500" component={Page500} />
        <PrivateRoute path="/" component={AppLayout} />
      </Switch>
    </Router>
  );
};

export default App;
