import React from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonProps } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { t } from 'helpers/i18n';

export const BackToHomeButton: React.FC<ButtonProps> = props => (
  <Link to="/">
    <Button type="primary" icon={<HomeOutlined />} {...props}>
      {t('BackToHome')}
    </Button>
  </Link>
);
