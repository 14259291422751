const IAM_SCOPES = [
  'openid',
  'profile',
  'read:permissions',
  // You can add more scopes (granted services) here
  'catalog',
];

export const DEFAULT_QUERY_PARAMS = {
  page: 1,
  pageSize: 20,
};

export const DEFAULT_QUERY_PARAMS_2 = {
  page: 0,
  pageSize: 20,
};

export const PRICE_FORMAT = /\B(?=(\d{3})+(?!\d))/g;

export default {
  IAM_SCOPES,
  ALL: 'all',
  TABLET_WIDTH: 768,
  DEFAULT_QUERY_PARAMS,
  DEFAULT_QUERY_PARAMS_2,
  PRICE_FORMAT,
};
