import { lazy } from 'react';
import {
  HomeOutlined,
  SearchOutlined,
  UserOutlined,
  OrderedListOutlined,
  FundOutlined,
} from '@ant-design/icons';
import { t } from 'helpers/i18n';

// App pages
const Home = lazy(() => import('containers/Home'));
const Search = lazy(() => import('containers/Search'));
const Product = lazy(() => import('containers/Product'));
const User = lazy(() => import('containers/User'));
const ListDeal = lazy(() => import('containers/ListDeal'));
const DealDetail = lazy(() => import('containers/DealDetail'));
const OrderHistory = lazy(() => import('containers/OrderHistory'));

/*
 * If route has children, it's a parent menu (not link to any pages)
 * You can change permissions to your IAM's permissions
 */
const routes = [
  // This is a menu/route which has no children (sub-menu)
  {
    exact: true,
    path: '/',
    name: t('Home'),
    component: Home,
    icon: HomeOutlined,
  },
  {
    exact: true,
    path: '/search',
    name: t('Search'),
    component: Search,
    icon: SearchOutlined,
  },
  {
    exact: true,
    path: '/productOffer',
    name: t('Product'),
    component: Product,
    icon: OrderedListOutlined,
  },
  {
    exact: true,
    path: '/user',
    name: t('User'),
    component: User,
    icon: UserOutlined,
  },
  {
    exact: true,
    path: '/order-history',
    name: t('Order-History'),
    component: OrderHistory,
    icon: UserOutlined,
  },
  {
    exact: true,
    path: '/deals',
    name: t('Deal'),
    component: ListDeal,
    icon: FundOutlined,
  },
  {
    exact: true,
    path: '/deal-detail/:id',
    name: t('Deal-Detail'),
    component: DealDetail,
    icon: FundOutlined,
  },
];

export default routes;
