import { getResult } from 'helpers/request';
import { requestServices } from '../index';
import axios from 'axios';

const { voucherClient, voucherClient2 } = requestServices;

const baseURL = 'https://api.vouchertoday.org';
// const baseURL = 'https://lucky-roses-battle-103-17-236-24.loca.lt/';

export const findVoucherByLink = async (link: string) => {
  return voucherClient.get(`findVoucher?link=${link}`).then(getResult);
};

export const listVoucher = async () => {
  return voucherClient.get('voucherList').then(getResult);
};

export const getDeals = async (params?: string, ctoken?: string | null) => {
  if (ctoken) {
    const newAxiosInstance = axios.create({
      baseURL: window.REACT_APP_VOUCHER_ENDPOINT,
      headers: {
        ctoken: ctoken,
      },
    });

    return newAxiosInstance
      .get('productOffer', {
        params: params,
      })
      .then(getResult);
  }
  return voucherClient
    .get('productOffer', {
      params: params,
    })
    .then(getResult);
};

export const getUserData = async (token?: string) => {
  // if (token) {
  //   const newAxiosInstance = axios.create({
  //     baseURL: window.REACT_APP_VOUCHER_ENDPOINT,
  //     headers: { Authorization: `${encodeURIComponent(unescape(token))}` },
  //   });
  //
  //   return newAxiosInstance.get('userinfo').then(getResult);
  // } else {
  return voucherClient.get('userinfo').then(getResult);
  // }
};

export const getOrderList = async (params: any) => {
  return voucherClient
    .get('orderList', {
      params: params,
    })
    .then(getResult);
};

export const saveFcmToken = async (token: string) => {
  return voucherClient
    .post('token', {
      token: token,
    })
    .then(getResult);
};

export const getCustomerDeals = async (
  params?: string,
  ctoken?: string | null,
  token?: string
) => {
  if (ctoken) {
    const newAxiosInstance = axios.create({
      baseURL: baseURL,
      headers: {
        ctoken: ctoken,
      },
    });

    return newAxiosInstance
      .get('/api/customer/deals', {
        params: params,
      })
      .then(getResult);
  }
  return voucherClient2
    .get('/api/customer/deals', {
      params: params,
    })
    .then(getResult);
};

export const getDetailDeal = async (
  dealId?: number,
  ctoken?: string | null
) => {
  if (ctoken) {
    const newAxiosInstance = axios.create({
      baseURL: baseURL,
      headers: {
        ctoken: ctoken,
      },
    });

    return newAxiosInstance
      .get(`/api/customer/deals/${dealId}`)
      .then(getResult);
  }
  return voucherClient2.get(`/api/customer/deals/${dealId}`).then(getResult);
};

export const registerDeal = async (dealId?: number, ctoken?: string | null) => {
  if (ctoken) {
    const newAxiosInstance = axios.create({
      baseURL: baseURL,
      headers: {
        ctoken: ctoken,
      },
    });

    return newAxiosInstance
      .get(`/api/customer/deals/register/${dealId}`)
      .then(getResult);
  }
  return voucherClient2
    .get(`/api/customer/deals/register/${dealId}`)
    .then(getResult);
};

export const uploadDeal = async (data?: object, ctoken?: string | null) => {
  if (ctoken) {
    const newAxiosInstance = axios.create({
      baseURL: baseURL,
      headers: {
        ctoken: ctoken,
      },
    });

    return newAxiosInstance
      .post('/api/customer/orders/update', { ...data })
      .then(getResult);
  }
  return voucherClient2
    .post('/api/customer/orders/update', { ...data })
    .then(getResult);
};

export const getOrderHistory = async (ctoken?: string | null) => {
  if (ctoken) {
    const newAxiosInstance = axios.create({
      baseURL: baseURL,
      headers: {
        ctoken: ctoken,
      },
    });

    return newAxiosInstance.get(`/api/customer/orders`).then(getResult);
  }
  return voucherClient2.get(`/api/customer/orders`).then(getResult);
};

export const getDetailOrder = async (
  orderId?: object,
  ctoken?: string | null
) => {
  if (ctoken) {
    const newAxiosInstance = axios.create({
      baseURL: baseURL,
      headers: {
        ctoken: ctoken,
      },
    });

    return newAxiosInstance
      .get(`/api/customer/orders/${orderId}`)
      .then(getResult);
  }
  return voucherClient2.get(`/api/customer/orders/${orderId}`).then(getResult);
};

export default {
  findVoucherByLink,
  listVoucher,
  getDeals,
  getUserData,
  getOrderList,
  getCustomerDeals,
  getDetailDeal,
  registerDeal,
  uploadDeal,
  getOrderHistory,
  getDetailOrder,
};
