import { message } from 'antd';
import { useEffect, useState } from 'react';
import {
  findVoucherByLink,
  getCustomerDeals,
  getDeals,
  getDetailDeal,
  getDetailOrder,
  getOrderList,
  getUserData,
  listVoucher,
  registerDeal,
  uploadDeal,
} from '../services/apis/voucher';
import { IData, OData } from '../interfaces/voucher';

const useProduct = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<any>([]);

  const searchVoucher = async (link: string) => {
    try {
      setLoading(true);
      const result = await findVoucherByLink(link);
      setProduct(result);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const searchLink = sessionStorage.getItem('search');

    if (searchLink) {
      searchVoucher(searchLink).then();
    }
  }, []);

  return {
    loading,
    product,
    searchVoucher,
  };
};

const useVoucher = () => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [vouchers, setVouchers] = useState<any>([]);

  const getListVoucher = async () => {
    try {
      setFetching(true);
      const result = await listVoucher();
      if (result) {
        const data = Object.entries(result);
        setVouchers(data);
      }
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getListVoucher().then();
  }, []);

  return {
    fetching,
    vouchers,
  };
};

const useDeals = (defaultQuery?: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>();
  const [ctoken, setCtoken] = useState<string | null>('');
  const [filter, setFilter] = useState<string>('1');

  const [params, setParams] = useState(defaultQuery);
  const [deals, setDeals] = useState<Array<any>>([]);
  const [total, setTotal] = useState<number>(0);
  const [defaultDeals, setDefaultDeals] = useState<Array<any>>(deals);

  const getListDeal = async () => {
    try {
      setLoading(true);
      let query = { ...params };
      if (searchKey) {
        query.keyword = searchKey;
      }
      query.filter = filter;
      const result = await getDeals(query, ctoken);
      if (result) {
        const { Total, Products } = result;

        setDeals(Products ? Products : []);
        setTotal(Total);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params) {
      getListDeal().then();
    }
  }, [params]);

  useEffect(() => {
    if (params) {
      getListDeal().then();
    }
    setDefaultDeals([]);
  }, [filter]);

  return {
    loading,
    searchKey,
    setSearchKey,
    deals,
    params,
    setParams,
    total,
    setCtoken,
    filter,
    setFilter,
    defaultDeals,
    setDefaultDeals,
  };
};

const useUser = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [token, setToken] = useState<string>();
  const [userData, setUserData] = useState<any>();
  const [orders, setOrders] = useState<any>();
  const [params, setParams] = useState<any>();
  const [total, setTotal] = useState<number>();

  const getData = async () => {
    try {
      setLoading(true);
      const result = await getUserData(token);
      if (result) {
        setUserData(result);
      }
    } finally {
      setLoading(false);
    }
  };

  const FetchOrders = async () => {
    try {
      setFetching(true);
      const result = await getOrderList(params);
      if (result) {
        const { Orders } = result;
        setOrders(Orders);
        setTotal(result.Total);
      }
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (params) {
      FetchOrders().then();
    }
  }, [params]);

  useEffect(() => {
    getData().then();
  }, []);

  return {
    loading,
    setToken,
    userData,
    fetching,
    orders,
    params,
    setParams,
    total,
  };
};

const useCustomerDeal = (defaultQuery?: any, defaultId?: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>();
  const [ctoken, setCtoken] = useState<string | null>('');
  const [token, setToken] = useState<string>('');

  const [params, setParams] = useState(defaultQuery);
  const [dealId, setDealId] = useState<any>(0);
  const [dealIdRegister, setDealIdRegister] = useState(defaultId);
  const [deals, setDeals] = useState<Array<any>>([]);
  const [deal, setDeal] = useState<IData>();
  const [orderId, setOrderId] = useState<Number>(0);
  const [dataUpdate, setDataUpdate] = useState<any>();
  const [notification, setNotification] = useState<string | null>('');
  const [orderDetail, setOrderDetail] = useState<OData>();
  const [checkRegisterDeal, setCheckRegisterDeal] = useState<boolean>(false);
  const getCustomerDeal = async () => {
    try {
      setLoading(true);
      let query = { ...params };
      if (searchKey) {
        query.keyword = searchKey;
      }
      const result = await getCustomerDeals(query, ctoken, token);
      if (result && result.message !== 'error') {
        const { message, data } = result;
        setNotification(message);
        setDeals(data ? data : []);
      } else if (result && result.message === 'error') {
        message.error(result.data);
      }
    } finally {
      setLoading(false);
    }
  };

  const getDeal = async () => {
    try {
      setLoading(true);
      const result = await getDetailDeal(dealId, ctoken);
      if (result && result.message !== 'error') {
        const { message, data } = result;
        setNotification(message);
        setDeal(data ? data : {});
        setOrderId(data?.orderId ? data.orderId : null);
      } else if (result && result.message === 'error') {
        message.error(result.data);
      }
    } finally {
      setLoading(false);
    }
  };

  const orderDeal = async () => {
    try {
      setLoading(true);
      const result = await registerDeal(dealIdRegister, ctoken);
      if (result && result.message !== 'error') {
        const { message, data } = result;
        setNotification(message);
        setCheckRegisterDeal(true);
        setOrderId(data.orderId ? data.orderId : null);
      } else if (result && result.message === 'error') {
        setDealIdRegister(defaultId);
        message.error(result.data);
      }
    } finally {
      setLoading(false);
    }
  };

  const updateDeal = async () => {
    try {
      setLoading(true);
      const result = await uploadDeal(dataUpdate, ctoken);
      if (result && result.message !== 'error') {
        const { message, data } = result;
        setNotification(message + data);
        getOrder().then();
      } else if (result && result.message === 'error') {
        message.error(result.data);
      }
    } finally {
      setLoading(false);
    }
  };

  const getOrder = async () => {
    try {
      setLoading(true);
      const result = await getDetailOrder(orderId, ctoken);
      if (result && result.message !== 'error') {
        const { message, data } = result;
        setNotification(message);
        setOrderDetail(data ? data : null);
      } else if (result && result.message === 'error') {
        message.error(result.data);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params) {
      getCustomerDeal().then();
    }
  }, [params]);

  useEffect(() => {
    if (dealId) {
      getDeal().then();
    }
  }, [dealId]);

  useEffect(() => {
    if (dealIdRegister) {
      orderDeal().then();
    }
  }, [dealIdRegister]);

  useEffect(() => {
    if (dataUpdate) {
      updateDeal().then();
    }
  }, [dataUpdate]);

  useEffect(() => {
    if (orderId) {
      getOrder().then();
    }
  }, [orderId]);

  return {
    loading,
    searchKey,
    setSearchKey,
    deals,
    deal,
    orderId,
    orderDetail,
    params,
    notification,
    checkRegisterDeal,
    setParams,
    setDealId,
    setDealIdRegister,
    setCtoken,
    setToken,
    setDataUpdate,
    setOrderDetail,
  };
};

export default {
  useVoucher,
  useProduct,
  useDeals,
  useUser,
  useCustomerDeal,
};
