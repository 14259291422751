import React from 'react';
import { Route } from 'react-router-dom';
import { userServices } from 'services';
import { IRoute } from 'interfaces';

const PrivateRoute = ({
  component: Component,
  ...rest
}: Omit<IRoute, 'name'>) => {
  // Check if user is logged in or not
  if (!userServices.isLoggedIn()) {
    userServices.login();
    return null;
  }

  return (
    <Route {...rest} render={routeProps => <Component {...routeProps} />} />
  );
};

export default PrivateRoute;
