export enum EOrderStatus {
  APPROVED = 'MoveToBalance',
  CANCELED = 'CancelPendingBalance',
  WAITING = 'AddedToPendingBalance',
}

const ORDER_STATUS: { [key: string]: any } = {
  [EOrderStatus.APPROVED]: {
    text: 'Đã duyệt',
    code: EOrderStatus.APPROVED,
    color: '#30CD60',
  },
  [EOrderStatus.CANCELED]: {
    text: 'Đã huỷ',
    code: EOrderStatus.CANCELED,
    color: '#eb1f3a',
  },
  [EOrderStatus.WAITING]: {
    text: 'Đợi duyệt',
    code: EOrderStatus.WAITING,
    color: '#f78212',
  },
};

export default {
  ORDER_STATUS,
};
