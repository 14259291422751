import firebase from 'firebase/app';
import 'firebase/messaging';

const config = {
  apiKey: 'AIzaSyA4MCi3T3Hb530DJsj7oDSVYB12zucNwbs',
  authDomain: 'vouchertoday-b20af.firebaseapp.com',
  projectId: 'vouchertoday-b20af',
  storageBucket: 'vouchertoday-b20af.appspot.com',
  messagingSenderId: '91915837434',
  appId: '1:91915837434:web:9813f675b7ef42d1158e43',
  measurementId: 'G-CVD2CXLL0D',
};
let messaging;
if (firebase.messaging.isSupported()) {
  firebase.initializeApp(config);
  messaging = firebase.messaging();
}

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then(firebaseToken => {
        resolve(firebaseToken);
      })
      .catch(err => {
        reject(err);
      });
  });

export const onMessageListener = () =>
  new Promise(resolve => {
    messaging.onMessage(payload => {
      resolve(payload);
    });
  });
