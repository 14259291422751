import TekoID from 'teko-oauth2';
import { browserHistory } from 'helpers';

const isLoggedIn = () => {
  // @ts-ignore
  return true;
};

const login = () => {
  // @ts-ignore
  TekoID.user.login();
};

const logout = () => {
  // @ts-ignore
  TekoID.user.logout(window.location.origin);
};

const getAccessToken = () => {
  // @ts-ignore
  return TekoID.user.getAccessToken();
};

const getUserInfo = () => {
  // @ts-ignore
  return TekoID.user.getUserInfo();
};

const denyAccess = () => {
  browserHistory.push('/403');
};

export default {
  isLoggedIn,
  login,
  logout,
  getAccessToken,
  getUserInfo,
  denyAccess,
};
